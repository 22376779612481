// Sets an empty array for the cmsable overlays
var customOverlays = []
// Create data structure for Overlays

for (key in dataObj.ground_overlays) {
  var overlay = dataObj.ground_overlays[key]

  // Sets variable for overlay image
  var overlayImage = overlay.overlay_image

  // Gets lat, long positions
  var imageBounds = {
    north: Number(overlay.lat_1),
    south: Number(overlay.lat_2),
    east: Number(overlay.lng_2),
    west: Number(overlay.lng_1)
  }

  // Creates the object for the google maps api
  customOverlays[key] = new google.maps.GroundOverlay(overlayImage, imageBounds)

  var buttonText
  // Check if button text is empty
  if (overlay.button_text === null) {
    buttonText = overlay.name
  } else {
    buttonText = overlay.button_text
  }

  $('.map-overlays').append('<li><a class="custom-overlay-btn" id="overlay-btn-' + key + '" href=""><span></span>' + buttonText + '</a></li>')

  gMap.addControl({
    position: 'bottom_left',
    // Icon uploaded from CMS
    content: overlay.name,
    id: 'cd-' + key,
    events: {
      click: function (key) {
        $(this).toggleClass('active')
        var overlayId = $(this).attr('id').replace('cd-', '')

        if ($(this).hasClass('active')) {
          customOverlays[overlayId].setMap(gMap.map)
        } else {
          customOverlays[overlayId].setMap(null)
        }
      }
    }
  })
}

$('.custom-overlay-btn').click(function (event) {
  event.preventDefault()
  $(this).toggleClass('active-custom-overlay')

  if ($(this).hasClass('active-custom-overlay')) {
    $(this).addClass('active-custom-overlay')
  }

  var clickedOverlay = $(this).attr('id').replace('overlay-btn-', '')

  $('#cd-' + clickedOverlay).trigger('click')
})
